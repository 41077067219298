<template>
  <div>
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :close-on-click-modal="false"
      >
        <el-form-item label="メッセージ" prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="send()"
          >確 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "sendMessage",
    props: {
        sendStatus: {
            type:Boolean,
            default:() => {
                return false
            }
        }
    },
    watch: {
        sendStatus(newVal) {
            this.ruleForm.desc = "";
            this.dialogVisible = newVal;
        },
        dialogVisible(newVal) {
            if(!newVal) {
                this.$emit("childStatus",false)
            }
        }
    },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        desc: "",
      },
      rules: {
        desc: [{ required: true, message: "请填写发送内容", trigger: "blur" }],
      },
    };
  },

  mounted() {

  },

  methods: {
      send() {
          this.dialogVisible = false;

        this.$emit("sendMessageContent",this.ruleForm.desc)
      }
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="friendList">
    <div class="friend-tab">
      <ul>
        <li
          :class="{ active: activeIndex == index }"
          v-for="(item, index) in friendType"
          :key="item.fid"
          @click="active(index, item.fid)"
        >
          {{ item.type }}
        </li>
      </ul>
    </div>
    <div class="form-box">
      <el-input placeholder="入力して下さい" v-model="searchFriend">
        <template slot="append">
          <!-- 搜索 -->
          <el-button type="danger" @click="searchFriendBtn"
            >検索</el-button
          ></template
        >
      </el-input>
    </div>
    <div class="table-box">
      <el-table :data="tableData" border style="width: 100%">
        <!-- 用户名 -->
        <el-table-column prop="nickname" label="ニックネーム" width="280">
        </el-table-column>
        <!-- 个人主页 -->
        <el-table-column prop="id" label="プロフィール">
          <template scope="scope">
            <!-- 简介 -->
            <span @click="handleClick(scope.row)" class="style"> プロフィール</span>
          </template>
        </el-table-column>
        <!-- 送信 -->
        <el-table-column prop="id" label="メッセージ">
          <template scope="scope">
            <!-- 送信 -->
            <span class="style" @click="sendMessage(scope.row)">メッセージ</span>
          </template>
        </el-table-column>
        <!-- 解除好友 -->
        <el-table-column prop="friendid" label="友達解除">
          <template scope="scope">
            <span class="style" @click="handeDdeleteFrien(scope.row)"
              >解除</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <send-message
      :sendStatus="sendStatus"
      @childStatus="childStatus"
      @sendMessageContent="sendMessageContent"
    ></send-message>
    <el-drawer  :visible.sync="drawer" direction="rtl" size="50%">
        <div class="registerInfo">
          <div class="icon" v-if="userInfo.icon">
            <img :src="'/userupload/' + userInfo.icon" alt="">
          </div>
        <el-descriptions :label-style="LS" title="ユーザー情報" :column="1" style="margin-top:20px" border>
          <el-descriptions-item label="アイコン" v-if="userInfo.pic">  <el-avatar :size="80" :src="'/userupload/' + userInfo.pic"></el-avatar> </el-descriptions-item>
        	<el-descriptions-item label="ニックネーム">{{userInfo.nickname}} </el-descriptions-item>
          <el-descriptions-item label="登録属性" >{{ userInfo.loginattr == 1 ? 'クリエイター ' : '編集者'}} </el-descriptions-item>
           <el-descriptions-item v-if="userInfo.loginattr == 1" label="参加したい工程" style="width:100px">
            <span style="padding: 0 5px" v-for="(item,index) in positions">{{item.position}}</span>
          </el-descriptions-item>
         
               <el-descriptions-item label="媒体">
            <!-- <span style="padding: 0 5px" v-for="(item,index) in media">{{ item.media }}</span> -->
             <span style="padding: 0 5px" v-for="(item,index) in medias">{{item.media}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="ジャンル">
            <span style="padding: 0 5px" v-for="(item,index) in typebigs">{{item.typeb}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="創作/エッセイ">
            <span v-if="userInfo.creativecap == 1" style="margin-left:15px">創作</span>
            <span v-else-if="userInfo.creativecap == 2" style="margin-left:15px">エッセイ</span>
            <span v-else>創作 エッセイ </span>
          </el-descriptions-item>
          	<el-descriptions-item v-if="userInfo.theme && userInfo.theme != '#'" label="興味のある題材">
              <p v-for="(item,index) in userInfo.theme.split('#')" :key="index">{{item}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.introduction" label="自己PR">
              <p>{{userInfo.introduction}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.cartoon" label="好きな漫画">
				      <p>{{userInfo.cartoon}}</p>
			      </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.cartooner" label="好きな作家">
              <p>{{userInfo.cartooner}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.cute" label="萌え">
              <p>{{userInfo.cute}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.drawmethod && userInfo.loginattr == 1" label="作画方法">
              <p>{{userInfo.drawmethod == 1 ? "アナログ" : "デジタル"}}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.drawmethod && userInfo.loginattr == 2" label="所属編集部" >{{ userInfo.drawmethod == 1 ? '出版社編集部所属 ' : 'フリーランス'}}</el-descriptions-item>
            <el-descriptions-item v-if="userInfo.reward != '#' && userInfo.reward" label="受賞歴">
              <!-- <p v-for="(item,index) in userInfo.reward.split("#")" :key="index">{{index}}</p> -->
              <p>{{ userInfo.reward }}</p>
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.experience && userInfo.loginattr == 1" label="漫画経験">
              <template v-if="userInfo.experience.length > 1">
                <span style="margin-left:15px">アシスタント</span>
                <span style="margin-left:15px">雑誌掲載</span>
              </template>
            <template  v-else-if="userInfo.experience == 1">
              <span v-if="userInfo.experience[0] == 1">アシスタント</span>
              <span style="margin-left:15px" v-else>雑誌掲載</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item v-if="achievements.length && achievements[0].selfProduVal" label="製作実績">
            <div style="margin-bottom: 10px" class="achive_" v-for="(item,index) in achievements">
              <span v-if="item.selfProduVal" style="padding: 0 10px 0 0">{{item.selfProduVal == '1' ? '商業' : '自主制作'}}</span>
              <span v-if="item.colorVal" style="padding: 0 10px 0 0">{{item.colorVal == '1' ? 'カラー' : '白黒'}} </span>
              <span v-if="item.isSerialVal" style="padding: 0 10px 0 0">{{item.isSerialVal == '1' ? '読切' : '連載'}}</span>
              <span v-if=" item.serialSizeVal" style="padding: 0 10px 0 0">{{ item.serialSizeVal == '1' ? '縦スク' : '見開き' }}</span>
              <p style="padding: 0 10px 0 0" v-if="item.workName">{{ item.workName }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.author">{{ item.author }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.desc">{{ item.desc }}</p>
          </div>
        </el-descriptions-item>
         <el-descriptions-item v-if="userInfo.introduce" label="ポートフォリオ">
            <p>{{userInfo.introduce}}</p>
          </el-descriptions-item>
          <el-descriptions-item v-if="userInfo.adscartoon" label="広告マンガ製作依頼" >{{ userInfo.adscartoon == 1 ? '受ける ' : '受けない'}}</el-descriptions-item>
          <el-descriptions-item v-if="userInfo.publish" label="海外への出版" >{{ userInfo.publish == 1 ? '希望する ' : '希望しない'}}</el-descriptions-item>
            </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import service from "@/service";
import sendMessage from "../../../commpoents/sendMessage";
export default {
  components: {
    sendMessage,
  },
  data() {
    return {
      LS: {
      'color': '#000',
      'font-weight':'700',
      'text-align': 'right',
      'width': '180px',
      'word-break': 'keep-all'
    },
      friendType: [
        {
          fid: 1,
          type: "原作者",
        },
        {
          fid: 2,
          type: "編集者",
        },
        {
          fid: 3,
          type: "上流アシスタント",
        },
        {
          fid: 5,
          type: "アシスタント",
        },
      ],
      activeIndex: '9',
      roleId: 1,
      searchFriend: "",
      tableData: [],
      sendStatus: false,
      fid: "",
      pic: "/userupload/",
      drawer: false,
      userInfo: {},
      positions: [],
      medias: [],
      typebigs: [],
      optionArr: [],
      themeArr: [],
      typebsArr: [],
      media: [],
      classification: [],
      achievements: [],
    };
  },
  mounted() {
    this.getFriendList();
    this.getType();
  },
  watch: {
    userInfo: {
      handler(val) {
        let arr = val.typebs.split(",");
        this.typebsArr = [];
        if (this.optionArr.length && arr.length) {
          this.optionArr.forEach((item, index) => {
            for (let i = 0; i < arr.length; i++) {
              if (item.id == arr[i]) {
                this.typebsArr.push(item);
              }
            }
          });
        }
        this.themeArr = val.theme.split("#");
      },
      deep: true,
    },
  },
  methods: {
    //获取分类
    getType() {
      service.personal
        .getTypeData()
        .then((res) => {
          if (res.status === 200) {
            this.optionArr = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(data) {
      // this.$refs.UpperDrawer.drawer = true;
      this.drawer = true;
      service.personal
        .getUserInfoData({ userid: data.friendid })
        .then((res) => {
          if (res.status === 200) {
            this.userInfo = res.data.data;
            this.positions = res.data.positions;
            this.medias = res.data.medias;
            this.typebigs = res.data.typebigs;
            this.achievements = res.data.data.achievements.split("#")
            this.achievements = this.achievements.map(item => {
              let arr = item.split(';')
              return {
                selfProduVal: arr[0],
                colorVal: arr[1],
                isSerialVal: arr[2],
                serialSizeVal: arr[3],
                workName: arr[4],
                author: arr[5],
                desc: arr[6],
              }
            })
            this.media = res.data.medias;
            this.classification = res.data.typebigs
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    active(i, id) {
      this.activeIndex = i;
      this.roleId = id;
      this.getSearchList({ keyword: this.searchFriend, role: id });
    },
    getFriendList() {
      service.personal
        .getFriendList()
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSearchList(data) {
      service.personal
        .getSearchList(data)
        .then((res) => {
          if (res.status === 200 && res.data.length) {
            this.tableData = res.data;
          }
          if (!res.data.length) {
            this.$message.warning("この条件でデータなし");
            this.tableData = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handeDdeleteFrien(data) {
       this.$confirm('友達リストより削除しますか？', 'ヒント', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.deleteFriend({ fid: data.friendid });
        }).catch(() => {
                  
        });
      
    },
    deleteFriend(data) {
      service.personal
        .deleteFriend(data)
        .then((res) => {
          if (res.status === 200) {
            this.getSearchList({
              keyword: this.searchFriend,
              role: this.roleId,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchFriendBtn() {
      let params = {
        keyword: this.searchFriend,
        role: this.roleId,
      };
      this.getSearchList(params);
    },
    sendMessage(data) {
      this.fid = data.friendid;
      this.sendStatus = true;
    },
    childStatus(val) {
      this.sendStatus = val;
    },
    sendMessageContent(text) {
      if (text) {
        let params = {
          fid: this.fid,
          msg: text,
        };
        service.personal
          .sendMessage(params)
          .then((res) => {
            if (res.status === 200) {
              this.$message.success("メッセージが送信しました");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("空送信が出来ません！");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.friendList {
  width: 100%;
  .friend-tab {
    width: 100%;
    ul {
      display: flex;
      li {
        padding: 15px;
        cursor: pointer;
        font-size: 18px;
      }
      li.active {
        color: #f03747;
      }
    }
  }
  .form-box {
    padding: 0 15px;
    margin-top: 10px;
  }
  .table-box {
    padding: 0 15px;
    margin-top: 20px;
    .style {
      cursor: pointer;
      color: #1875d2;
    }
  }
  .wrap {
    padding: 15px;
    .augi {
      text-align: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .img-box {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        display: block;
        span {
          display: block;
          margin: 10px 0 0 0;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
    }
    .type {
      margin: 40px 0 0 0;
      .contentBox {
        color: #606266;
        font-size: 14px;
        .content {
          padding: 0 10px;
          min-width: 20px;
          color: #606266;
        }
      }
    }
  }
}
// 注册信息
::v-deep.registerInfo {
  padding: 20px;
  .icon {
    height: 160px;
    text-align: center;
    img {
      max-height: 100%;
    }
  }
  ul {
    width: 100%;
    li {
      span {
        margin-left: 20px;
        font-size: 16px;
      }
      height: 32px;
      h4 {
        font-size: 18px;
      }
      div {
        padding-left: 20px;
      }
    }
  }
}
</style>
